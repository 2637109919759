<template>
    <div>
        <!-- TODO: Header Image Optimization -->
        <div v-if="dataLoaded" id="article-header" class="entry-short"
            v-bind:style="{ backgroundImage: 'url(' + headerImage + ')' }">
            <div class="context shadow">
                <h1>Blog</h1>
            </div>
        </div>
        <div v-if="dataLoaded" class="article">
            <div class="blog-header">
                <h1 class="title">{{ localize(blogArticle.attributes.title) }}</h1>

                <div class="authors">
                    {{ $t(authors.length > 1 ? 'authors' : 'author') }}:
                    <span v-for="(author, index) of authors">
                        <router-link :to="`/team/${author.slug}`" v-if="author.slug">{{ author.name }}</router-link>
                        <span v-else>{{ author.name }}</span>
                        <span v-if="index + 1 < authors.length">, </span>
                    </span>
                </div>

                <ContentCategories articleEntity="blog" :categories="blogArticle.attributes.contentCategories.data" />

                <p class="date">{{ dateFormat(articleDate) }}</p>
            </div>

            <ArticlePageBuilder :cmsBlocks="blogArticle.attributes.articleBlocks" />
        </div>
    </div>
</template>

<script>
import { blogArticleQuery } from '@/queries'
import { config } from '@/config'
import { dateFormat } from '@/helper'

export default {
    name: 'BlogArticle',

    components: {
        ArticlePageBuilder: () => import('@/components/ArticlePageBuilder'),
        ContentCategories: () => import('@/components/PageBuilder/ContentCategories.vue')
    },

    data() {
        return {
            cmsPage: null,
            dateFormat: dateFormat
        }
    },

    apollo: {
        blogArticle: {
            query: blogArticleQuery,
            variables() {
                return {
                    title: this.$route.params.title
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.blogArticles.data[0]
        }
    },

    metaInfo() {
        var meta = []

        if (this.metaTitle) {
            meta.push({
                vmid: 'og:title',
                name: 'og:title',
                content: this.metaTitle
            })
        }
        if (this.metaDescription) {
            meta.push({
                vmid: 'description',
                name: 'description',
                content: this.metaDescription
            })
            meta.push({
                vmid: 'og:description',
                name: 'og:description',
                content: this.metaDescription
            })
        }
        if (this.metaImage) {
            meta.push({
                vmid: 'og:image',
                name: 'og:image',
                content: this.metaImage
            })
        }

        return {
            title: this.metaTitle,
            meta: meta
        }
    },

    computed: {
        dataLoaded() {
            return this.blogArticle != null
        },

        articleDate() {
            return new Date(this.blogArticle.attributes.dateOverride ?? this.blogArticle.attributes.createdAt);
        },

        headerImage() {
            return this.blogArticle.attributes.headerImage ?
                this.getImageUrl(this.localize(this.blogArticle.attributes.headerImage).data.attributes.url) :
                require('@/assets/Teamwork.png')
        },

        metaTitle() {
            if (this.blogArticle) {
                var title = this.localize(this.blogArticle.attributes.metaTitle ?? this.blogArticle.attributes.title);
                return config.globalPageTitle + ' - ' + title
            } else {
                return null
            }
        },

        metaDescription() {
            return this.blogArticle ? this.localize(this.blogArticle.attributes.metaDescription ?? this.blogArticle.attributes.excerpt) : null;
        },

        metaImage() {
            if (this.blogArticle) {
                return this.blogArticle.attributes.metaImage ? this.blogArticle.attributes.metaImage.data.attributes.url : this.localize(this.blogArticle.attributes.titleImage).data.attributes.url
            } else {
                return null
            }
        },

        authors() {
            if (this.blogArticle && this.blogArticle.attributes.authors.length > 0) {
                return this.blogArticle.attributes.authors.map(author => {
                    const { data: team_member } = author.team_member;

                    if (team_member && team_member.attributes.name) {
                        return {
                            name: author.name ?? team_member.attributes.name,
                            slug: team_member.attributes.hasProfilePage ? team_member.attributes.slug : null
                        }
                    }

                    return {
                        name: author.name ?? null,
                        slug: null
                    };
                });
            }

            return null
        }
    }
}
</script>

<style lang="scss">
#article-header {
    background-size: cover;
    height: 530px;

    .context {
        padding-left: 30px;
        padding-right: 30px;
    }

    h1 {
        font-size: 74px;

        @include breakpoint('tablet') {
            font-size: 62px;
        }

        @include breakpoint('mobile') {
            font-size: 48px;
        }
    }
}

.article {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
    padding-bottom: 70px;

    .blog-header {
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: 0 0;
        }

        .title {
            font-size: 30px;
            text-align: start;
            margin-bottom: 10px;
        }

        .date {
            text-align: start;
            margin-bottom: 24px;
            font-weight: thin;
            font-size: 16px;
        }

        .authors {
            margin-bottom:10px;
        }
    }

    .excerpt {
        text-align: start;
        font-weight: 600;
        margin-bottom: 24px;
    }

    p {
        font-size: 18px;
        text-align: start;

        &.large {
            font-size: 30px;
            text-align: center;
        }
    }

    h4 {
        font-size: 18px;
    }
}
</style>
